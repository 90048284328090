import React from 'react';

import styles from './hint-panel.module.scss';

export function Hint({ children }) {
  return (
    <div className={styles.hinweise}>
      <p className={styles.hinweiseHeading}>Expertentipp</p>
      <div className={styles.hinweiseContent}>
        <p>{children}</p>
      </div>
    </div>
  );
}
