import React from 'react';
import styles from './list.module.scss';

export function ListItem({ children }) {
  return <li className={styles.listItem}>{children}</li>;
}

export function List({ children }) {
  return <ul className={styles.list}>{children}</ul>;
}
