const { buildYup } = require('json-schema-to-yup');

export const formValidationSchema = (formFields = []) => {
  var config = { errMessages: {} };
  var schema = { type: 'object', properties: {}, required: [] };
  formFields.forEach((field) => {
    const { name } = field;
    schema.properties[name] = getPropertyValues(field);
    config.errMessages[name] = getConfigValues(field);
  });
  return buildYup(schema, config);
};

function getPropertyValues(field) {
  const { _type, isRequired, label, max, min } = field;
  const properties = { description: label, required: isRequired, minLength: min, maxLength: max };
  switch (_type) {
    case 'stringFormField':
      return Object.assign({}, properties, { type: 'string' });
    case 'emailFormField':
      return Object.assign({}, properties, { type: 'string', format: 'email' });
    case 'numberFormField':
      return Object.assign({}, properties, { type: 'number' });
    case 'checkboxFormField':
      return { type: 'string', required: isRequired };
    case 'selectFormField':
      return { type: 'string', required: isRequired };
  }
}

function getConfigValues(field) {
  const { _type, isRequired } = field;
  let config = {};
  if (isRequired) {
    config['required'] = 'Diese Angabe brauchen wir auf jeden Fall';
  }
  if (_type === 'checkboxFormField' && isRequired) {
    config['required'] = 'Diese Angabe brauchen wir auf jeden Fall';
  }
  if (_type === 'emailFormField') {
    config['format'] = 'Ungültige E-Mail Adresse';
  }
  if (_type === 'numberFormField') {
    config['type'] = 'Ungültige Telefonnummer';
  }
  return config;
}
