import React from 'react';
import styles from './block-title-with-subtitle.module.scss';

export const BlockTitleWithSubtitle = ({ node = {} }) => {
  const { titleWithSubtitles = {} } = node;

  return (
    <div className={styles.titleSubtitleWrapper}>
      {titleWithSubtitles &&
        titleWithSubtitles.map((item, idx) => {
          return (
            <div key={idx} className={styles.titleSubtitleHolder}>
              <h1 className={styles.title}>{item.title}</h1>
              <p className={styles.subtitle}>{item.subtitle}</p>
            </div>
          );
        })}
    </div>
  );
};
