import BaseBlockContent from '@sanity/block-content-to-react';
import React from 'react';
import clsx from 'clsx';

import { BlockContentColumns } from 'gatsby-theme-sanity-evelan/src/components/block-content/block-content-columns';
import { Slideshow } from 'gatsby-theme-sanity-evelan/src/components/block-content/slideshow/slideshow';
import { Player } from 'gatsby-theme-sanity-evelan/src/components/block-content/player';
import { List, ListItem } from './list';
import { H2 } from 'gatsby-theme-sanity-evelan/src/components/block-content/h2';
import { H3 } from 'gatsby-theme-sanity-evelan/src/components/block-content/h3';
import { Hint } from './hint-panel.js';
import { BlockContentCta } from 'gatsby-theme-sanity-evelan/src/components/block-content/block-content-cta';
import { ExternalLinkButton } from 'gatsby-theme-sanity-evelan/src/components/button/external-link-button';
import { InternalLink } from 'gatsby-theme-sanity-evelan/src/components/link/internal-link';
import { BlockTitleWithSubtitle } from './block-title-with-subtitle';
import { Spacer } from './spacer';
import { ErrorBoundary } from 'gatsby-theme-sanity-evelan/src/components/error-boundary/error-boundary';
import { PictureAsset } from 'gatsby-theme-sanity-evelan';
import { isAsset } from 'gatsby-theme-sanity-evelan/src/components/asset/asset-utils';
import { Form } from '../forms/form';

import styles from './block-content.module.scss';

const sanityLocation = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
};

const serializers = {
  marks: {
    'hi-primary': ({ children }) => {
      return <span className={clsx(styles.coloredTextPrimary)}>{children}</span>;
    },
    'hi-secondary': ({ children }) => {
      return <span className={clsx(styles.coloredTextSecondary)}>{children}</span>;
    },
    link: ({ children, mark }) => {
      return (
        <ExternalLinkButton href={mark.href} variant="text" className={styles.externalLink}>
          {children}
        </ExternalLinkButton>
      );
    },
    internalLink: ({ children, mark }) => {
      return <InternalLink node={mark}>{children}</InternalLink>;
    },
  },
  types: {
    block: ({ node: { style, first } = {}, children }) => {
      switch (style) {
        case 'h1':
          return <h1 className={clsx(styles.h1, first && styles.firstHeading)}>{children}</h1>;

        case 'h2':
          return <H2 first={first}>{children}</H2>;

        case 'h3':
          return <H3 first={first}>{children}</H3>;

        case 'h4':
          return <h4 className={clsx(styles.h4, first && styles.firstHeading)}>{children}</h4>;

        case 'h5':
          return <h5 className={clsx(styles.h5, first && styles.firstHeading)}>{children}</h5>;

        case 'blockquote':
          return <blockquote>{children}</blockquote>;

        case 'hint':
          return <Hint>{children}</Hint>;

        case 'subtitle':
          return <p className={styles.subtitle}>{children}</p>;

        default:
          return <p className={styles.text}>{children}</p>;
      }
    },
    blockTitle: () => {
      return <p>Block title should go here</p>;
    },
    figure: ({ node } = {}) => {
      return isAsset(node) ? (
        <figure className={styles.figure}>
          <PictureAsset image={node} isFluid alt={node.alt} />
          {node?.caption && Object.keys(node?.caption).length > 0 && <figcaption>{node.caption}</figcaption>}
        </figure>
      ) : null;
    },
    slideshow: ({ node } = {}) => {
      return <Slideshow {...node} />;
    },
    video: ({ node } = {}) => {
      const { url } = node;
      return <Player url={url} />;
    },
    blockContentColumns: ({ node } = {}) => {
      return <BlockContentColumns node={node} />;
    },
    blockContentCta: ({ node } = {}) => {
      return <BlockContentCta node={node} />;
    },
    formSelect: ({ node }) => {
      const { _id, actionButtonLabel, description, fields, successMessage, title } = node.formType[0];
      return (
        <Form
          identifier={_id}
          actionButtonLabel={actionButtonLabel}
          formDescription={<BlockContent blocks={description} />}
          formFields={fields}
          successMessage={<BlockContent blocks={successMessage} />}
          formHeading={<BlockContent blocks={title} />}
        />
      );
    },
    blockTitleWithSubtitle: ({ node } = {}) => {
      return <BlockTitleWithSubtitle node={node} />;
    },
    spacer: ({ node } = {}) => {
      return <Spacer node={node} />;
    },
  },
  list: List,
  listItem: ListItem,
};

export const BlockContent = ({ blocks = [] }) => {
  return (
    <ErrorBoundary>
      <BaseBlockContent
        blocks={blocks.map((block, idx) =>
          idx === 0
            ? {
                ...block,
                first: true,
              }
            : block
        )}
        serializers={serializers}
        {...sanityLocation}
      />
    </ErrorBoundary>
  );
};
