import React from 'react';
import clsx from 'clsx';
import styles from './checkbox.module.scss';

export function Checkbox({ id = '', name, label = '', children, error, className, ...other }) {
  const htmlId = id || `checkbox-${name}`;

  return (
    <label className={clsx(styles.checkboxWrapper, className)} htmlFor={htmlId}>
      <input {...other} name={name} type="checkbox" id={id} />
      <div className={styles.checkbox}>
        <span className={clsx(styles.checkboxIcon, error && styles.error)} />
        <div className={styles.checkboxText}>
          <div className={clsx(styles.checkboxLabel, error && styles.error)}>{label || children}</div>
          {error && <p className={styles.textFieldErrorMsg}>{error}</p>}
        </div>
      </div>
    </label>
  );
}
