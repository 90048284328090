import React from 'react';
import { useFormik } from 'formik';

import { FormWrapper } from 'gatsby-theme-sanity-evelan/src/components/forms/form-wrapper';
import { FormField } from './inputs/form-field';
import { Button } from '../button/button';
import { CircleLoader } from 'gatsby-theme-sanity-evelan';
import { FormDataProtection } from 'gatsby-theme-sanity-evelan/src/components/forms/form-data-protection';
import { formValidationSchema } from './form-validation';
import { useFormFetch } from 'gatsby-theme-sanity-evelan/src/components/forms/form-fetch-hook';

function getInitialValues(formFields = []) {
  let initialValues = {};
  formFields.forEach((formField) => {
    const { _type, name } = formField;
    switch (_type) {
      case 'stringFormField':
      case 'emailFormField':
      case 'numberFormField':
        initialValues[name] = '';
        break;
      case 'checkboxFormField':
        initialValues[name] = false;
        break;
      case 'selectFormField':
        initialValues[name] = '';
    }
  });
  return initialValues;
}

export function Form({ identifier, actionButtonLabel, formDescription, formFields = [], successMessage, formHeading }) {
  const formFetch = useFormFetch();
  const { onSubmit, showConfirmation, setShowConfirmation, serverError, setServerError } = formFetch;
  const initialFormValues = Object.assign({}, { formId: identifier }, getInitialValues(formFields));

  const {
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting,
    handleSubmit,
    values,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: initialFormValues,
    validationSchema: formValidationSchema(formFields),
    onSubmit,
  });

  const baseInputProps = {
    onChange: handleChange,
    onBlur: handleBlur,
  };

  return (
    <FormWrapper
      onSubmit={handleSubmit}
      showConfirmation={showConfirmation}
      setShowConfirmation={setShowConfirmation}
      serverError={serverError}
      setServerError={setServerError}
      resetForm={resetForm}
      formMobileWide
      formHeading={formHeading}
      formDescription={formDescription}
      successMessage={successMessage}
    >
      {formFields.map((formField) => {
        const { name } = formField;
        return (
          <FormField
            fieldProperties={formField}
            key={name}
            value={values[name]}
            onSelect={(selected) => {
              setValues({ ...values, [name]: selected.target.id });
            }}
            name={name}
            error={touched[name] && errors[name]}
            {...baseInputProps}
          />
        );
      })}
      <Button type="submit" variant="secondary" showAlt={isSubmitting}>
        {actionButtonLabel}
        {isSubmitting && <CircleLoader />}
      </Button>
      <FormDataProtection />
    </FormWrapper>
  );
}
