import React, { useContext } from 'react';
import clsx from 'clsx';
import styles from './block-content-cta-custom.module.scss';
import { LinkButton } from 'gatsby-theme-sanity-evelan/src/components/button/link-button';
import { getUrlFromNavItem } from 'gatsby-theme-sanity-evelan/src/lib/helpers';
import { LayoutContext } from 'gatsby-theme-sanity-evelan/src/contexts/LayoutContext';

export function BlockContentCta({ node = {} }) {
  const { startPageId } = useContext(LayoutContext);

  const {
    cta: { label: ctaLabel, reference: { _id: ctaPageId, _type: ctaPageType, slug: { current: ctaSlug } } = {} } = {},
    title,
    ctaSecondary: {
      label: ctaSecLabel,
      reference: { _id: ctaSecPageId, _type: ctaSecPageType, slug: { current: ctaSecSlug } = {} } = {},
    } = {},
  } = node;

  return (
    <div className={styles.container}>
      <div className={styles.ctaHolder}>
        {title ? <p className={clsx(styles.title)}>{title}</p> : ''}
        <div className={styles.ctaBtnHolder}>
          <LinkButton
            className={clsx(styles.ctaText, styles.ctaBtn)}
            variant="primary"
            to={getUrlFromNavItem({ id: ctaPageId, type: ctaPageType, slug: ctaSlug }, startPageId)}
          >
            {ctaLabel}
          </LinkButton>
          {ctaSecLabel && ctaSecSlug ? (
            <LinkButton
              className={clsx(styles.ctaText, styles.ctaBtn)}
              variant="secondary"
              to={getUrlFromNavItem(
                {
                  id: ctaSecPageId,
                  type: ctaSecPageType,
                  slug: ctaSecSlug,
                },
                startPageId
              )}
            >
              {ctaSecLabel}
            </LinkButton>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}
