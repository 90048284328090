import React from 'react';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

import { TextField } from 'gatsby-theme-sanity-evelan/src/components/forms/inputs/text-field';
import { Checkbox } from './checkbox';
import { Dropdown } from '../../dropdown/dropdown';

import { BlockContent } from '../../block-content/block-content';
import styles from './form-field.module.scss';

export function FormField({ fieldProperties, value, onSelect, ...other }) {
  const { _type, label, name, options, multirow } = fieldProperties;
  switch (_type) {
    case 'stringFormField': {
      return <TextField label={label} placeholder={label} type={multirow ? 'textarea' : 'text'} {...other} />;
    }
    case 'emailFormField': {
      return <TextField label={label} placeholder={label} {...other} />;
    }
    case 'numberFormField': {
      return <TextField label={label} placeholder={label} {...other} />;
    }
    case 'selectFormField': {
      return (
        <Dropdown
          label={`${label}: `}
          name={name}
          value={value === '' ? value : [value]}
          onSelect={onSelect}
          options={options.map((option) => {
            const { value, _type } = option;
            const formattedValue = _type === 'formDateTimeOption' ? formatDate(value) : value;
            return { id: formattedValue, name: formattedValue };
          })}
          className={styles.dropdownWrapper}
          {...other}
        />
      );
    }
    case 'checkboxFormField': {
      return <Checkbox id={name} checked={!!value} name={name} label={<BlockContent blocks={label} />} {...other} />;
    }
  }
}

function formatDate(date) {
  return format(new Date(date), 'PPPp', { locale: de });
}
